import React, { FunctionComponent, useState } from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Button } from '@wh-components/core/Button/Button'
import { useModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalNavigator'
import { ResetAllButton } from '../ResetAllButton/ResetAllButton'
import { NavigatorProps } from '../NavigatorProps'
import { GroupedPossibleValues } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/GroupedPossibleValues'
import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { Checkbox, CheckboxSize } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { css } from 'styled-components'
import NewIcon from '@wh-components/icons/CatNewconstruction'
import { upsertSearchPreferences } from '@bbx/search-journey/common/api/searchApiClient'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'

export interface NavigatorModalProps {
    label?: string
    modalHeader?: string
    checkboxSize?: CheckboxSize | undefined
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
}

export const ModalNavigatorModal: FunctionComponent<
    Pick<NavigatorProps, 'navigator' | 'onSearch'> & NavigatorModalProps & ReturnType<typeof useModalNavigator>
> = ({
    checkboxState,
    handleChange,
    onSubmitMultiSelect,
    modalOpen,
    closeModal,
    modalHeader,
    navigator,
    onSearch,
    checkboxSize,
    checkedContent,
    uncheckedContent,
    setCheckboxState,
}) => {
    const [profileData] = useProfileData()

    const [saveAttributePreference, setSaveAttributePreference] = useState(false)
    const [inProgress, setInProgress] = useState(false)

    const handleSubmit: typeof onSubmitMultiSelect = (event) => {
        const metadata = navigator.metadata

        setInProgress(true)
        if (saveAttributePreference && metadata && navigator.metadata?.savePreferencesLink && isUserLoggedIn(profileData)) {
            const data = {
                parameterName: metadata.parameterName ?? '',
                attributeName: metadata.treeAttributeXmlNames?.[0] ?? '',
                values: Object.keys(checkboxState)
                    .filter((key) => checkboxState[key])
                    .map((key) => `${key}`),
            }

            return upsertSearchPreferences(navigator.metadata.savePreferencesLink, data)
                .catch((err) => console.error('error updating user preferences', err))
                .finally(() => onSubmitMultiSelect(event))
                .finally(() => {
                    setSaveAttributePreference(false)
                    setInProgress(false)
                })
        } else {
            return onSubmitMultiSelect(event).finally(() => setInProgress(false))
        }
    }

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            fullScreen={{ phone: true, tablet: false }}
            width={{ phone: 'auto', tablet: '720px' }}
            header={modalHeader ?? navigator.label}
            testId={`${navigator.id}-modal`}
            footer={
                <Box display="flex" justifyContent="space-between">
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        marginRight="m"
                        color="complimentary"
                        testId={`${navigator.id}-dismiss-modal`}
                        onClick={closeModal}
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={inProgress}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        testId={`${navigator.id}-submit-modal`}
                        onClick={handleSubmit}
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={inProgress}
                    >
                        Fertig
                    </Button>
                </Box>
            }
            footerSeparator={true}
            scrollShadow={true}
        >
            {navigator.selectedValues.length >= 1 && (
                <Box
                    height={{ phone: 48, tablet: 27 }}
                    display="flex"
                    alignItems="center"
                    position="absolute"
                    top={{ phone: 0, tablet: 'l' }}
                    right={{ phone: 56, tablet: 32 }}
                >
                    <ResetAllButton
                        navigatorId={navigator.id}
                        onSearch={onSearch}
                        contextLink={navigator.resetAllInformation?.resetAllUrl}
                        onClick={() => {
                            setCheckboxState((prevState) => {
                                Object.keys(prevState).forEach((key) => (prevState[key] = false))
                                return prevState
                            })
                        }}
                    />
                </Box>
            )}
            <form
                onSubmit={handleSubmit}
                css={css`
                    height: 100%;
                `}
            >
                <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    {navigator.groupedPossibleValues.map((group) => {
                        return (
                            <GroupedPossibleValues
                                navigator={navigator}
                                handleChange={handleChange}
                                checkboxState={checkboxState}
                                groupName={group.label}
                                possibleValues={group.possibleValues}
                                key={group.label}
                                checkboxSize={checkboxSize}
                                checkedContent={checkedContent}
                                uncheckedContent={uncheckedContent}
                                disabled={inProgress}
                            />
                        )
                    })}
                    {navigator.metadata?.savePreferencesLink && (
                        <Box backgroundColor="palette.babyseal" borderRadius="m" padding="s">
                            <Checkbox
                                id="save-filter-preference-checkbox"
                                testId="save-filter-preference-checkbox"
                                checked={saveAttributePreference}
                                onChange={(e) => {
                                    setSaveAttributePreference(e.target.checked)
                                }}
                                disabled={inProgress}
                                label={
                                    <Box display="flex" flexDirection="column" justifyContent="space-between" marginLeft="s">
                                        <Box fontWeight="bold" display="flex" alignItems="center">
                                            Größe merken
                                            <NewIcon
                                                size="medium"
                                                marginLeft="xs"
                                                css={css`
                                                    fill: ${(p) => p.theme.colors.palette.accent.secondary.main};
                                                `}
                                            />
                                        </Box>
                                        <Text>Ich möchte mir meine Größe(n) für das nächste Mal merken.</Text>
                                    </Box>
                                }
                            />
                        </Box>
                    )}
                </Box>
                <Button type="submit" display="none" />
            </form>
        </Modal>
    )
}
